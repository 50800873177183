import 'twin.macro';
import { SelectedSport } from '@/graphql/gql/graphql';
import Divider from '@/components/Divider';
import ErrorComponent from '@/components/empty/ErrorComponent';
import FeaturedSport from './FeaturedSport';

type SportSectionProps = {
  selectedSports: SelectedSport[],
};

const SportSection = ({ selectedSports }: SportSectionProps) => (
  <div tw="bg-white font-normal shadow-low md:rounded">
    {selectedSports.length === 0 ? (
      <div tw="p-4">
        <ErrorComponent />
      </div>
    ) : (
      <>
        <FeaturedSport selectedSport={selectedSports[0]} />
        <div tw="my-6 mx-4 lg:mx-6">
          <Divider />
        </div>
        <FeaturedSport selectedSport={selectedSports[1]} />
      </>
    )}
  </div>
);

export default SportSection;
