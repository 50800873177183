import cx from 'classnames';
import { useState, useMemo } from 'react';
import { SPORTSBOOK_BETSLIP_CTA_CLICKED, SPORTSBOOK_ODDS_SELECTED, SPORTSBOOK_READY_TO_BET_MODAL_CLOSED } from '@/constants/amplitude';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import Styles from './OddsBtn.module.scss';
import Lock from '../svg/Lock';

type OddsBtnProps = {
  className: string,
  handicap?: number | string,
  isSuspended?: boolean,
  onClick: () => void,
  odds: number | string,
};

/**
 * All uses of OddsBtn that should link to Sportsbook must use this Hook to control
 * a <RedirectToSportbook> modal. This ensures users must confirm their intent before
 * navigating away.
 */
export const useRedirectConfirmationModal = (
  sport: string,
  competition: string,
  ampModule?: string,
) => {
  const [url, setUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { logAmplitudeEvent } = useAmplitude();
  const ampProps = useMemo(() => ({
    Module: ampModule ?? 'coupon',
    Sport: sport ?? '',
    Competition: competition ?? '',
  }), [sport, competition, ampModule]);

  const onOddsClick = (redirectUrl: string) => {
    setUrl(redirectUrl);
    setShowModal(true);
    logAmplitudeEvent(SPORTSBOOK_ODDS_SELECTED, { ...ampProps });
  };

  const onClickPrimaryCta = () => {
    logAmplitudeEvent(SPORTSBOOK_BETSLIP_CTA_CLICKED, { ...ampProps, 'Link URL': url, 'Link Text': 'go_to_betslip', Module: 'ready_to_bet_modal' });
  };

  const onRequestClose = () => {
    setShowModal(false);
    logAmplitudeEvent(SPORTSBOOK_READY_TO_BET_MODAL_CLOSED, { ...ampProps, Module: 'ready_to_bet_modal' });
  };

  const redirectToSportsbookProps = {
    sportsbookRedirectUrl: url,
    isOpen: showModal,
    onClickPrimaryCta,
    onRequestClose,
  };

  return { onOddsClick, redirectToSportsbookProps };
};

const OddsBtn = ({
  handicap,
  isSuspended,
  onClick,
  odds,
  className,
}: OddsBtnProps) => (
  <button
    type="button"
    className={cx(Styles.button, className)}
    onClick={onClick}
    disabled={isSuspended}
  >
    {isSuspended ? (
      <Lock />
    ) : (
      <>
        {handicap ? <div className={Styles.handicap}>{handicap}</div> : null}
        <div className={Styles.odds}>{odds}</div>
      </>
    )}
  </button>
);

export default OddsBtn;
