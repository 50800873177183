import tw, { styled } from 'twin.macro';
import useAuthorList from '@/utils/hooks/useAuthorList';
import useSports from '@/utils/hooks/useSports';
import Template3Column from '@/components/templates/author/Template3Column';
import Tabs from '@/components/Tabs';
import ErrorComponent from '@/components/empty/ErrorComponent';
import LoadingBackdrop from '@/components/LoadingBackdrop';

const CardContent = styled.div(() => [
  tw`p-0 min-h-[26rem] flex flex-col justify-center items-center md:flex-row md:gap-4 md:p-4 md:min-h-[31.0625rem]`,
]);

const AuthorContainer = () => {
  const { selectableSportsForAuthors } = useSports();
  const {
    data,
    setSport,
    selectedSport,
    isLoading,
    isFetching,
    isError,
  } = useAuthorList('All', selectableSportsForAuthors);

  const loading = isLoading || isFetching;
  const success = !isFetching && data;
  const [spotlightArticle, ...remainingArticles] = data ?? [];
  const [col2Articles, col3Articles] = [
    remainingArticles.slice(0, 5),
    remainingArticles.slice(5, 10),
  ];

  return (
    <div tw="bg-white font-normal shadow-low md:rounded">
      <h3 tw="p-4 text-base font-bold leading-5 text-grey-d4 md:text-2xl">More from our authors</h3>
      <div tw="flex justify-start gap-4 overflow-scroll border-b border-grey-l3 px-4 pt-3 scrollbar-hide">
        <Tabs
          dataTestId="sports-tabs"
          items={selectableSportsForAuthors.map(({ name }) => name)}
          selected={selectedSport}
          setSelected={setSport}
        />
      </div>
      <CardContent>
        <LoadingBackdrop loading={loading} />
        {isError && <ErrorComponent ctaButtonLabel="Refresh Module" />}
        {success && (
          <Template3Column
            col1Article={spotlightArticle}
            col2Articles={col2Articles}
            col3Articles={col3Articles}
          />
        )}
      </CardContent>
    </div>
  );
};

export default AuthorContainer;
