import 'twin.macro';
import { Article, Tool, Maybe } from '@/graphql/gql/graphql';
import ArticleCard from '@/components/ArticleCard';
import Divider from '@/components/Divider';
import SpotlightArticle from '@/components/SpotlightArticle';
import ToolComponent from '@/components/home/hero/Tool';

type Template3ColumnWithToolsProps = {
  col1Article: Article,
  col2Articles: Article[],
  col3Tools: Maybe<Tool>[],
};

const Template3ColumnWithTools = ({
  col1Article,
  col2Articles = [],
  col3Tools = [],
}: Template3ColumnWithToolsProps) => {
  const {
    title,
    description,
    slug,
    subHeading,
    firstPublishedAt,
    hero,
    author,
    sport: {
      name: sportName,
      slug: sportSlug,
    },
  } = col1Article;

  return (
    <div tw="col-span-3 grid gap-4 lg:grid-cols-6">
      <div tw="col-span-3">
        <SpotlightArticle
          author={author}
          fullwidthMobileImage
          image={hero?.imageUrl?.url}
          link={`/${slug}`}
          origin="home_sport_articles"
          publishedOn={firstPublishedAt}
          snippet={subHeading ?? description}
          sportName={sportName}
          sportSlug={sportSlug}
          tagType="navy"
          title={title}
        />
      </div>
      <div tw="col-span-3 xl:col-span-2">
        {col2Articles.map((article, idx) => (
          <div key={article.id}>
            <ArticleCard
              author={article.author}
              image={article.hero?.imageUrl?.url}
              link={`/${article.slug}`}
              origin="home_sport_articles"
              publishedOn={article.firstPublishedAt}
              sportName={article.sport.name}
              sportSlug={article.sport.slug}
              title={article.title}
            />
            {idx < col2Articles.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
      <div tw="col-span-3 lg:col-span-6 xl:col-span-1">
        <div tw="flex w-full flex-col gap-4 p-4 pt-0 lg:flex-row lg:p-0 xl:flex-col">
          {col3Tools.map((tool) => (
            <ToolComponent
              origin="home_tool_list"
              key={`${tool?.title}-${tool?.url}`}
              description={tool?.title ?? ''}
              title={tool?.title ?? ''}
              url={tool?.url ?? ''}
              newWindow={tool?.newWindow}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Template3ColumnWithTools;
