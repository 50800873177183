import { useMemo } from 'react';
import { Article, Maybe, Tool, Template } from '@/graphql/gql/graphql';
import PoweredByNumberFire from '@/components/svg/PoweredByNumberFire';
import Template2Column from '@/components/templates/hero/Template2Column';
import Template3Column from '@/components/templates/hero/Template3Column';
import Template3ColumnWithTools from '@/components/templates/hero/Template3ColumnWithTools';
import ErrorComponent from '@/components/empty/ErrorComponent';
import styles from './hero.module.scss';

export type HeroProps = {
  articles: Article[],
  template: Template,
  tools?: Maybe<Tool>[],
};

const Hero = ({
  articles,
  template,
  tools = [],
}: HeroProps) => {
  const [spotlightArticle, ...remainingArticles] = articles;
  const [col2Articles, col3Articles] = useMemo(() => {
    const maxCol2Map = {
      [Template.Template1]: 6,
      [Template.Template2]: 5,
      [Template.Template3]: 4,
    };
    return [
      remainingArticles?.slice(0, maxCol2Map[template]),
      remainingArticles?.slice(maxCol2Map[template]),
    ];
  }, [remainingArticles, template]);

  const templateMap = {
    [Template.Template1]: (
      <Template2Column
        col1Article={spotlightArticle}
        col2Articles={col2Articles}
      />
    ),
    [Template.Template2]: tools.length && (
      <Template3ColumnWithTools
        col1Article={spotlightArticle}
        col2Articles={col2Articles}
        col3Tools={tools}
      />
    ),
    [Template.Template3]: (
      <Template3Column
        col1Article={spotlightArticle}
        col2Articles={col2Articles}
        col3Articles={col3Articles}
      />),
  };

  return (
    <div className={styles.heroContainer}>
      <div className={styles.cardContent}>
        {articles.length === 0 ? (
          <ErrorComponent ctaButtonLabel="Refresh Module" />
        ) : (
          <>
            <div className={styles.numberFire}>
              <PoweredByNumberFire height="18" width="136" />
            </div>
            {templateMap[template]}
          </>
        )}
      </div>
    </div>
  );
};

export default Hero;
