import { GroupControl } from '@fanduel/og-fe-library';
import Styles from './Tabs.module.scss';

type TabsProps = {
  items: string[],
  selected: string,
  setSelected: (value: string) => void,
  dataTestId?: string,
};

const Tabs = ({ items, selected, setSelected, dataTestId }: TabsProps) => (
  <GroupControl
    groupControlType="tabbed"
    dataTestId={dataTestId}
    items={items}
    selected={selected}
    setSelected={setSelected}
    className={Styles.groupControl}
  />
);

export default Tabs;
