import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Article, HomepageContent, Tool, SelectedSport, Template } from '@/graphql/gql/graphql';
import { getHomepage } from '../api';
import { filterEmpty } from '..';

type FilteredHomepageContent = {
  articles: Article[],
  selectedSports: SelectedSport[],
  template: Template,
  tools: Tool[],
};

const useHomepage = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['homepageQuery'],
    placeholderData: keepPreviousData,
    queryFn: () => getHomepage({ isPreview: false }),
    select: (response) => {
      const { articles, selectedSports, template, tools } = response.getHomepage as HomepageContent;
      const homepageContent = {} as FilteredHomepageContent;
      const filteredArticles = filterEmpty(articles || []) as Article[];
      const filteredTools = filterEmpty(tools || []) as Tool[];
      const filteredSports = filterEmpty(selectedSports || []) as SelectedSport[];
      homepageContent.articles = filteredArticles;
      homepageContent.selectedSports = filteredSports;
      homepageContent.template = template;
      homepageContent.tools = filteredTools;

      return homepageContent;
    },
  });

  return {
    data,
    isLoading,
    isError,
  };
};

export default useHomepage;
