import { SVGProps } from '@/types/svg';
import { colorGreyL3 } from '../../styles/manualVars';

const Lock = ({
  width = '23',
  height = '20',
  fill = colorGreyL3,
}: SVGProps) => (
  <svg data-testid="lock-icon" width={width} height={height} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2353 7.05882H18.2647C19.3836 7.05882 20.2941 7.85059 20.2941 8.82353V18.2353C20.2941 19.2082 19.3836 20 18.2647 20H4.73531C3.61643 20 2.7059 19.2082 2.7059 18.2353V8.82353C2.7059 7.85059 3.61643 7.05882 4.73531 7.05882H6.76473V4.11765C6.76473 1.84706 8.88884 0 11.5 0C14.1112 0 16.2353 1.84706 16.2353 4.11765V7.05882ZM11.5 10.5881C10.3811 10.5881 9.47056 11.3799 9.47056 12.3528C9.47056 13.1187 10.0374 13.7658 10.8235 14.0093V15.8823C10.8235 16.207 11.1266 16.4705 11.5 16.4705C11.8734 16.4705 12.1764 16.207 12.1764 15.8823V14.0093C12.9625 13.7658 13.5294 13.1187 13.5294 12.3528C13.5294 11.3799 12.6189 10.5881 11.5 10.5881ZM11.5 12.941C11.1266 12.941 10.8235 12.6763 10.8235 12.3528C10.8235 12.0293 11.1266 11.7646 11.5 11.7646C11.8734 11.7646 12.1765 12.0293 12.1765 12.3528C12.1765 12.6763 11.8734 12.941 11.5 12.941ZM8.11761 4.1176C8.11761 2.49642 9.63561 1.17642 11.5 1.17642C13.3643 1.17642 14.8823 2.49642 14.8823 4.1176V7.05878H8.11761V4.1176ZM18.2647 18.8235C18.6381 18.8235 18.9412 18.5588 18.9412 18.2353V8.82353C18.9412 8.49882 18.6381 8.23529 18.2647 8.23529H4.7353C4.36189 8.23529 4.05883 8.49882 4.05883 8.82353V18.2353C4.05883 18.5588 4.36189 18.8235 4.7353 18.8235H18.2647Z" fill={fill} />
  </svg>
);

export default Lock;
